<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.5" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_park" transform="translate(-1018 -2768)">
    <g data-name="Group 428" id="Group_428" transform="translate(-19 -236)">
     <rect :fill="background" data-name="Rectangle 218" height="183" id="Rectangle_218" transform="translate(1037 3004)" width="183"/>
     <path d="M84.729,2.25H10.168A10.17,10.17,0,0,0,0,12.418V86.979A10.17,10.17,0,0,0,10.168,97.147H84.729A10.17,10.17,0,0,0,94.9,86.979V12.418A10.17,10.17,0,0,0,84.729,2.25ZM50.838,63.255H40.67V73.423a3.4,3.4,0,0,1-3.389,3.389H30.5a3.4,3.4,0,0,1-3.389-3.389V25.974A3.4,3.4,0,0,1,30.5,22.585H50.838a20.335,20.335,0,1,1,0,40.67Zm0-27.113H40.67V49.7H50.838a6.778,6.778,0,1,0,0-13.557Z" data-name="Icon awesome-parking" fill="url(#linear-gradient)" id="Icon_awesome-parking" transform="translate(1081 3045.75)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>